import React, { useState, useEffect } from "react"
import {
  CommerceLayer,
  OrderStorage,
  OrderContainer,
  LineItemsContainer,
  LineItemsCount,
} from "@commercelayer/react-components"
import { useAuth } from "../hooks/useAuth"
import {
  Container,
  Box,
  Text,
  Flex,
  Heading,
  Input,
  Select,
  Grid,
  Label,
} from "@theme-ui/components"
import { useProducts } from "../hooks/useProducts"
import ReactPaginate from "react-paginate"
import ReactModal from "react-modal"
import Product from "./product"

const Dashboard = () => {
  const [products, setProducts] = useState(useProducts())
  const [filteredProducts, setFilteredProducts] = useState(products)
  const genders = [...new Set(products.map(a => a.gender))]
  const [gender, setGender] = useState("all")
  const categories = [
    ...new Set(
      products.map(a => {
        return a.category ? a.category.name : null
      })
    ),
  ]
  const [category, setCategory] = useState("all")
  function getSizes(filteredProducts) {
    const sizesReduced = []
    filteredProducts.map(a =>
      a.variants.map(variant => sizesReduced.push(variant.size.name))
    )
    return [...new Set(sizesReduced)]
  }
  const [sizes, setSizes] = useState(getSizes(filteredProducts))
  const [size, setSize] = useState("all")

  function getColors(filteredProducts) {
    const colorsReduced = []
    filteredProducts.map(a =>
      a.variants.map(variant => colorsReduced.push(variant.color.name))
    )
    return [...new Set(colorsReduced)]
  }
  const [colors, setColors] = useState(getColors(filteredProducts))
  const [color, setColor] = useState("all")

  const endpoint = "https://dkb.commercelayer.io"
  const { token, setToken, cartCounter, setCartCounter } = useAuth()
  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [accessToken, setAccessToken] = useState("")
  const [searchQuery, setSearchQuery] = useState("")

  const [page, setPage] = useState(0)
  const [currentItems, setCurrentItems] = useState(filteredProducts)
  const [pageCount, setPageCount] = useState(0)
  const [itemsPerPage, setItemPerPage] = useState(25)
  const [itemOffset, setItemOffset] = useState(0)

  const formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  })

  ReactModal.defaultStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 5000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      width: "644px",
      padding: "0 !important",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      maxHeight: "80vh",
    },
  }

  const openModal = product => {
    setSelectedProduct(product)
    setOpen(true)
  }

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(filteredProducts.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage))
    setSizes(getSizes(filteredProducts.slice(itemOffset, endOffset)))
    setColors(getColors(filteredProducts.slice(itemOffset, endOffset)))
  }, [itemOffset, itemsPerPage, filteredProducts])

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    setPage(event.selected)
  }

  useEffect(() => {
    const newOffset = (page * itemsPerPage) % filteredProducts.length
    setItemOffset(newOffset)
  }, [page])

  useEffect(() => {
    setAccessToken(token)
  }, [])

  function handleSelectChange(gender) {
    setGender(gender)
    setSize("all")
    setColor("all")
  }

  function handleCategoryChange(category) {
    setCategory(category)
    setSize("all")
    setColor("all")
  }

  function handleSizeChange(size) {
    setSize(size)
  }

  function handleColorChange(color) {
    setColor(color)
  }

  function hasSize(product) {
    if (size === "all") {
      return true
    } else {
      const checkSize = obj => obj.size.name === size
      console.log(size, product.variants.some(checkSize))
      return product.variants.some(checkSize)
    }
  }

  function hasColor(product) {
    if (color === "all") {
      return true
    } else {
      const checkSize = obj => obj.color.name === color
      console.log(color, product.variants.some(checkSize))
      return product.variants.some(checkSize)
    }
  }

  useEffect(() => {
    setSearchQuery("")
    if (gender === "all" && category === "all") {
      setFilteredProducts(products)
    } else if (gender === "all") {
      setFilteredProducts(
        products.filter(obj => {
          return obj.category.name === category
        })
      )
    } else if (category === "all") {
      setFilteredProducts(
        products.filter(obj => {
          return obj.gender === gender
        })
      )
    } else {
      setFilteredProducts(
        products.filter(obj => {
          return obj.gender === gender && obj.category.name === category
        })
      )
    }
  }, [category, gender])

  useEffect(() => {
    if (searchQuery === "") {
      const timer = setTimeout(() => {
        setFilteredProducts(products)
      }, 200)
      return () => clearTimeout(timer)
    } else {
      setFilteredProducts(
        products.filter(product => {
          return product.name.toLowerCase().includes(searchQuery.toLowerCase())
        })
      )
    }
  }, [searchQuery])

  useEffect(() => {
    setPage(0)
  }, [filteredProducts])

  return (
    <Box>
      <Container>
        <Flex sx={{ justifyContent: "space-between" }}>
          <Heading>Prodotti</Heading>
          <Input
            type="text"
            placeholder="Cerca per nome"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            sx={{
              fontSize: 1,
              paddingX: 3,
              paddingY: 2,
              borderColor: "#ccc",
              mb: 0,
              width: "50%",
            }}
          />
        </Flex>
      </Container>
      <Container
        sx={{
          marginTop: 0,
          marginBottom: 6,
          padding: 0,
          backgroundColor: "#ffffff",
          boxShadow:
            "rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px",
          borderRadius: "sm",
        }}
      >
        <Container sx={{ borderBottom: "1px solid #ddd" }}>
          <Grid columns={[4]}>
            <Box>
              <Label sx={{ fontWeight: "normal" }}>Categoria</Label>
              <Select
                sx={{ p: 3, borderColor: "darkBackground" }}
                onChange={e => handleCategoryChange(e.target.value)}
                value={category}
              >
                <option value="all">Tutte</option>
                {categories.map(category => (
                  <option value={category}>{category}</option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label sx={{ fontWeight: "normal" }}>Genere</Label>
              <Select
                sx={{ p: 3, borderColor: "darkBackground" }}
                onChange={e => handleSelectChange(e.target.value)}
                value={gender}
              >
                <option value="all">Tutti</option>
                {genders.map(gender => (
                  <option value={gender}>{gender}</option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label sx={{ fontWeight: "normal" }}>Taglia</Label>
              <Select
                sx={{ p: 3, borderColor: "darkBackground" }}
                onChange={e => handleSizeChange(e.target.value)}
                value={size}
              >
                <option value="all">Tutte</option>
                {sizes.map(size => (
                  <option value={size}>{size}</option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label sx={{ fontWeight: "normal" }}>Colore</Label>
              <Select
                sx={{ p: 3, borderColor: "darkBackground" }}
                onChange={e => handleColorChange(e.target.value)}
                value={color}
              >
                <option value="all">Tutti</option>
                {colors.map(color => (
                  <option value={color}>{color}</option>
                ))}
              </Select>
            </Box>
          </Grid>
        </Container>
        <Container sx={{ paddingTop: "8px" }}>
          <CommerceLayer accessToken={accessToken} endpoint={endpoint}>
            <OrderStorage persistKey="DKBITA">
              {currentItems && (
                <OrderContainer attributes={{ language_code: "it" }}>
                  <LineItemsContainer>
                    <LineItemsCount>
                      {props => {
                        setCartCounter(props.quantity)
                        return null
                      }}
                    </LineItemsCount>
                  </LineItemsContainer>
                  {currentItems.length === 0 && (
                    <Box>
                      <Box
                        sx={{
                          p: 3,
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Text>Nessun prodotto corrisponde all ricerca</Text>
                      </Box>
                    </Box>
                  )}
                  {currentItems.length !== 0 && (
                    <Box>
                      {currentItems.map(product => (
                        <>
                          {hasSize(product) && hasColor(product) && (
                            <Product
                              product={product}
                              size={size}
                              color={color}
                              handleSubmit={openModal}
                              key={product.id}
                            />
                          )}
                        </>
                      ))}
                    </Box>
                  )}
                </OrderContainer>
              )}
            </OrderStorage>
          </CommerceLayer>
        </Container>
        <Container
          sx={{
            borderTop: "1px solid #eee",
            ".pagination": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              fontSize: 1,
              li: {
                cursor: "pointer",
                color: "#666",
                mx: 3,
                "&.selected": {
                  a: {
                    color: "primary",
                    fontWeight: "bold",
                  },
                },
                "&:hover": {
                  color: "primary",
                },
              },
            },
          }}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="successiva ›"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="‹ precedente"
            containerClassName="pagination"
            forcePage={page}
          />
        </Container>
      </Container>
      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        {selectedProduct && (
          <Box sx={{ padding: 5 }}>
            <Heading mb={3}>{selectedProduct.name}</Heading>
            <Text
              as="p"
              sx={{
                mb: 3,
                px: 2,
                py: 1,
                backgroundColor: "#eeeeee",
                borderRadius: "sm",
                display: "inline-block",
              }}
            >
              Prezzo consigliato:{" "}
              {formatter.format((selectedProduct.retailPrice / 100).toFixed(2))}
            </Text>
            <Box
              dangerouslySetInnerHTML={{ __html: selectedProduct.description }}
              sx={{ h2: { fontSize: 3 } }}
            />
          </Box>
        )}
      </ReactModal>
    </Box>
  )
}

export default Dashboard
