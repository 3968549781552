import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { handleLogin } from "../utils/auth"
import { useAuth } from "../hooks/useAuth"
import { Box, Container, Input, Label, Grid } from "@theme-ui/components"
const isBrowser = typeof window !== `undefined`

const LoginForm = () => {
  const { token, setToken } = useAuth()
  const [user, setUser] = useState({
    username: ``,
    password: ``,
  })

  const [errorLogin, setErrorLogin] = useState("")

  const handleSubmit = async event => {
    event.preventDefault()

    const response = await handleLogin(user)

    if (!response) setErrorLogin("Username o password non validi")
    else {
      setToken(response.accessToken)
    }
  }

  useEffect(() => {
    if (token && isBrowser) {
      navigate(`/dashboard/`)
    }
  }, [token])

  return (
    <Container sx={{ width: "480px" }}>
      <Box
        as="form"
        method="post"
        onSubmit={event => {
          handleSubmit(event)
        }}
      >
        <Label htmlFor="email">Email</Label>
        <Input
          type="text"
          name="username"
          onChange={e => setUser({ ...user, username: e.target.value })}
          required
        />
        <Label htmlFor="email">Password</Label>
        <Input
          type="password"
          name="password"
          onChange={e => setUser({ ...user, password: e.target.value })}
          required
        />
        <Input
          type="submit"
          value="Accedi"
          sx={{
            mt: 0,
            backgroundColor: "primary",
            color: "white",
            paddingY: 3,
            transition: "background-color 300ms ease-in-out",
            "&:hover": {
              backgroundColor: "dark",
            },
          }}
        />
      </Box>
      {errorLogin.length > 0 && <div>{errorLogin}</div>}
      <Box
        sx={{
          textAlign: "right",
          a: {
            color: "#999999",
            fontSize: [1],
          },
        }}
      >
        <Link to="/forgot-password/">Password dimenticata?</Link>
      </Box>
    </Container>
  )
}

export default LoginForm
