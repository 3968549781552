import React, { useState, useEffect } from "react"
import { getSalesChannelToken, getCustomerToken } from "@commercelayer/js-auth"
import {
  Box,
  Flex,
  Checkbox,
  Grid,
  Image,
  Label,
  Card,
  Radio,
  Heading,
  Divider,
  Text,
  Container,
  Input,
  Button,
} from "@theme-ui/components"
import { Nav } from "../pages"
import { CheckCircle } from "react-feather"
import {
  CommerceLayer,
  OrderContainer,
  Errors,
  AddressesContainer,
  BillingAddressForm,
  AddressInput,
  AddressStateSelector,
  AddressCountrySelector,
  SaveAddressesButton,
  ShippingAddressForm,
  CustomerContainer,
  CustomerInput,
  SaveCustomerButton,
  Shipment,
  ShipmentsContainer,
  LineItemImage,
  LineItemName,
  LineItemQuantity,
  ShippingMethodName,
  ShippingMethod,
  ShippingMethodRadioButton,
  ShippingMethodPrice,
  LineItemsContainer,
  StockTransfer,
  StockTransferField,
  DeliveryLeadTime,
  ShipmentField,
  LineItem,
  BillingAddressContainer,
  Address,
  AddressField,
  ShippingAddressContainer,
  PaymentMethodsContainer,
  PlaceOrderContainer,
  PaymentMethod,
  PaymentMethodRadioButton,
  PaymentMethodName,
  PaymentMethodPrice,
  PaymentSource,
  PaymentSourceBrandIcon,
  PaymentSourceBrandName,
  PaymentSourceDetail,
  PaymentSourceEditButton,
  PrivacyAndTermsCheckbox,
  PlaceOrderButton,
  AddressCardsTemplate,
  SubTotalAmount,
  ShippingAmount,
  TaxesAmount,
  LineItemCode,
  TotalAmount,
} from "@commercelayer/react-components"
import { isEmpty } from "lodash"
import { useAuth } from "../hooks/useAuth"
import { useSDK } from "../hooks/useSDK"
import { GatsbyImage } from "gatsby-plugin-image"
import { useVariant } from "../hooks/useSKU"
import { Link } from "gatsby"

const CheckoutPage = page => {
  const [sdk, setSDK] = useState(useSDK())
  const orderId = page.uri.split("/").pop()
  const { token, setToken } = useAuth()
  const [accessToken, setAccessToken] = useState(token)
  const endpoint = "https://dkb.commercelayer.io"
  const [customerEmail, setCustomerEmail] = useState("")
  const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false)
  const [saveOnBlur, setSaveOnBlur] = useState(false)
  const [billingAddress, setBillingAddress] = useState({})
  const [shippingAddress, setShippingAddress] = useState({})
  const [shippingMethodName, setShippingMethodName] = useState("")
  const [shippingMethodId, setShippingMethodId] = useState("")
  const [showBillingAddressForm, setShowBillingAddressForm] = useState(null)
  const [showShippingAddressForm, setShowShippingAddressForm] = useState(null)
  const [billingFirstName, setBillingCompany] = useState("")
  const [paymentSource, setPaymentSource] = useState(null)
  const [checkoutStep, setCheckoutStep] = useState(0)
  const [order, setOrder] = useState(null)
  const config = { accessToken, endpoint }
  const allSkus = useVariant()
  const [orderPlaced, setOrderPlaced] = useState(false)
  const [customerAddresses, setCustomerAddresses] = useState(null)

  const handleOrder = async () => {
    if (order) {
    }
  }

  useEffect(() => {
    async function getCustomerAddress() {
      const customerAddressResponse = await sdk.customer_addresses.list()

      if (customerAddressResponse) {
        
        setCustomerAddresses(customerAddressResponse)
        if (customerAddressResponse.length > 0) {
          setShowBillingAddressForm(false)
          setShowShippingAddressForm(false)
        } else {
          setShowBillingAddressForm(true)
          setShowShippingAddressForm(true)
        }
      }
    }

    if (showBillingAddressForm === null) getCustomerAddress()
  }, [])

  useEffect(() => {
    async function getOrder() {
      const orderResponse = await sdk.orders.retrieve(orderId, {
        include: [
          "shipping_address",
          "billing_address",
          "payment_source",
          "shipments",
        ],
      })

      if (orderResponse) {

        let counter = 0;
        const shipments = (await sdk.shipping_methods.list()).first()
        const order = orderResponse

        if (
          order.payment_status === "authorized" ||
          order.status === "placed"
        ) {
          setOrderPlaced(true)
        }

        setCustomerEmail(order.customerEmail)

        if (order.billing_address) {
          const billing = order.billing_address
          setBillingCompany(billing.company ? billing.company : "")
          setBillingAddress({
            first_name: billing.first_name,
            last_name: billing.last_name,
            company: billing.company,
            line_1: billing.line_1,
            city: billing.city,
            country_code: billing.country_code,
            state_code: billing.state_code,
            zip_code: billing.zip_code,
            phone: billing.phone,
          })
        }

        if (order.shipping_address) {
          const shipping = order.shipping_address
          setShippingAddress({
            first_name: shipping.first_name,
            last_name: shipping.last_name,
            company: shipping.company,
            line_1: shipping.line_1,
            city: shipping.city,
            country_code: shipping.country_code,
            state_code: shipping.state_code,
            zip_code: shipping.zip_code,
            phone: shipping.phone,
          })
        }

        if (order.billing_address && order.shipping_address) {
          counter++
        }

        if (order.shipments && order.shipments[0]) {
          counter++
        }

        if (order.payment_source) {
          const paymentSourceResponse = order.payment_source
          counter++
        }
        
        setCheckoutStep(counter)
        setOrder(orderResponse)
      }
    }

    if (!order) getOrder()
    if (order) handleOrder()
  }, [token, order])

  const messages = [
    {
      code: "EMPTY_ERROR",
      resource: "billingAddress",
      field: "company",
      message: `Can't be blank`,
    },
    {
      code: "VALIDATION_ERROR",
      resource: "billingAddress",
      field: "email",
      message: `Must be valid email`,
    },
  ]

  const handleChange = shippingMethod => {
    setShippingMethodName(shippingMethod.name)
    setShippingMethodId(shippingMethod.id)
  }

  const handleClick = async e => {
    if (accessToken) {
      const config = { accessToken, endpoint }
      try {
        const orderCall = await sdk.orders.retrieve(orderId, {
          include: ["shipping_address", "billing_address", "payment_source"],
        })
        if (order.billing_address) {
          const billing = order.billing_address
          setBillingCompany(billing.company ? billing.company : "")
          setBillingAddress({
            first_name: billing.first_name,
            last_name: billing.last_name,
            company: billing.company,
            line_1: billing.line_1,
            city: billing.city,
            country_code: billing.country_code,
            state_code: billing.state_code,
            zip_code: billing.zip_code,
            phone: billing.phone,
          })
        }

        if (order.shipping_address) {
          const shipping = order.shipping_address
          setShippingAddress({
            first_name: shipping.first_name,
            last_name: shipping.last_name,
            company: shipping.company,
            line_1: shipping.line_1,
            city: shipping.city,
            country_code: shipping.country_code,
            state_code: shipping.state_code,
            zip_code: shipping.zip_code,
            phone: shipping.phone,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleShowBillingForm = () => {
    setShowBillingAddressForm(!showBillingAddressForm)
    setBillingCompany("")
  }
  const handleShowShippingForm = () =>
    setShowShippingAddressForm(!showShippingAddressForm)

  return (
    <Container
      sx={{
        input: {
          width: "100%",
          p: [3],
          border: "2px solid",
          borderColor: "gray",
          borderRadius: [4],
          cursor: "pointer",
          "&:focus": {
            outline: "none",
            color: "text",
            border: "2px solid",
            borderColor: "primary",
          },
        },
        select: {
          width: "100%",
          p: [3],
          border: "2px solid",
          borderColor: "gray",
          borderRadius: [4],
          cursor: "pointer",
          "&:focus": {
            outline: "none",
            color: "text",
            border: "2px solid",
            borderColor: "primary",
          },
        },
      }}
    >
      {orderPlaced ? (
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            svg: { stroke: "#90be6d" },
          }}
        >
          <CheckCircle size="120" />
          <h1>Ordine effettuato con successo</h1>
          <Link to={`/dashboard/ordini/${order.id}`}>Visualizza</Link>
        </Flex>
      ) : (
        ( order &&
        <CommerceLayer accessToken={accessToken} endpoint={endpoint}>
          
          <Box className="container mx-auto mt-5 px-5">
            <OrderContainer
              orderId={orderId}
              attributes={{
                cart_url: "https://yourdomain.com/cart",
                return_url: "https://yourdomain.com/return",
                privacy_url: "https://yourdomain.com/privacy",
                terms_url: "https://yourdomain.com/terms",
              }}
            >
              <CustomerContainer>
                <Flex>
                  <Box
                    sx={{
                      width: "60%",
                      flex: "1 0 auto",
                      mr: [4],
                      backgroundColor: "lightGrey",
                      p: [4],
                      borderRadius: [5],
                    }}
                  >
                    {/* <Box>
              <Box className="mt-1">
                <pre data-cy="current-customer-email">{`Current customer email: ${JSON.stringify(
                  customerEmail,
                  null,
                  2
                )}`}</pre>
              </Box>
            </Box> */}
                    <AddressesContainer
                      shipToDifferentAddress={shipToDifferentAddress}
                    >
                      <Box>
                        <Box>
                          <Heading as="h5" variant="h5" sx={{ mt: 0, mb: 4 }}>
                            Indirizzo di fatturazione
                          </Heading>
                        </Box>
                        <Box sx={{ pb: [showBillingAddressForm ? 2 : 1] }}>
                          <Label sx={{ alignItems: "center" }}>
                            <Radio
                              name="billing-mode"
                              value={showBillingAddressForm ? "false" : "true"}
                              defaultChecked={!showBillingAddressForm}
                              onChange={e => {
                                setShowBillingAddressForm(false)
                              }}
                              disabled={!customerAddresses || customerAddresses.length === 0}
                            />
                            Seleziona un indirizzo dai tuoi indirizzi di
                            fatturazione
                          </Label>
                        </Box>
                        <Box
                          sx={{
                            py: [3],
                            display: !showBillingAddressForm ? "block" : "none",
                          }}
                        >
                          <BillingAddressContainer>
                            <Grid
                              columns={[1, 1, 1]}
                              gap={[3]}
                              sx={{
                                ".billing-address-card-ctn": {
                                  ".billing-address-card": {
                                    borderRadius: [4],
                                    border: "2px solid",
                                    borderColor: "transparent",
                                    position: "relative",
                                  },
                                  "&.selected-address-card": {
                                    ".billing-address-card": {
                                      borderColor: "primary",
                                    },
                                    ".checked-icon": {
                                      boxSizing: "border-box",
                                      position: "absolute",
                                      top: "50%",
                                      display: "block",
                                      transform: "translateY(-50%)",
                                      width: "22px",
                                      height: "22px",
                                      border: "2px solid",
                                      borderRadius: "100px",
                                      borderColor: "primary",
                                      "&::after": {
                                        content: "''",
                                        display: "block",
                                        boxSizing: "border-box",
                                        position: "absolute",
                                        left: "3px",
                                        top: "-1px",
                                        width: "6px",
                                        height: "10px",
                                        color: "primary",
                                        borderColor: "primary",
                                        borderWidth: "0 2px 2px 0",
                                        borderStyle: "solid",
                                        transformOrigin: "bottom left",
                                        transform: "rotate(45deg)",
                                      },
                                    },
                                  },
                                },
                              }}
                            >
                              <Address
                                className="billing-address-card-ctn"
                                selectedClassName="selected-address-card"
                                data-cy="customer-billing-address"
                                deselect={showBillingAddressForm}
                                onSelect={e => {
                                  return (
                                    showBillingAddressForm &&
                                    setShowBillingAddressForm(false)
                                  )
                                }}
                              >
                                <Card
                                  className="billing-address-card"
                                  sx={{ backgroundColor: "light" }}
                                >
                                  <Flex sx={{ ml: [4], p: { px: [3] } }}>
                                    <Box
                                      sx={{
                                        boxSizing: "border-box",
                                        position: "absolute",
                                        top: "50%",
                                        left: [3],
                                        display: "block",
                                        transform: "translateY(-50%)",
                                        width: "22px",
                                        height: "22px",
                                        border: "2px solid",
                                        borderRadius: "100px",
                                        borderColor: "gray",
                                      }}
                                      className="checked-icon"
                                    ></Box>
                                    <AddressField name="full_name"></AddressField>
                                    <AddressField name="full_address"></AddressField>
                                  </Flex>
                                </Card>

                                {/* 'city' | 'company' | 'country_code' | 'first_name' |
                    'last_name' | 'line_1' | 'line_2' | 'phone' | 'state_code' |
                    'zip_code' | 'billing_info' */}
                              </Address>
                            </Grid>
                          </BillingAddressContainer>
                        </Box>
                      </Box>
                      <Box>
                        <Box sx={{ py: [2] }}>
                          <Label sx={{ alignItems: "center" }}>
                            <Radio
                              name="billing-mode"
                              value={showBillingAddressForm ? "true" : "false"}
                              defaultChecked={showBillingAddressForm}
                              onChange={e => {
                                setShowBillingAddressForm(true)
                              }}
                            />
                            Aggiungi un nuovo indirizzo di fatturazione
                          </Label>
                        </Box>
                        <Card
                          sx={{
                            pt: [2],
                            pb: [2],
                            px: [3],
                            borderRadius: [4],
                            border: "2px solid",
                            position: "relative",
                            borderColor: showBillingAddressForm
                              ? "primary"
                              : "transparent",
                            backgroundColor: "light",
                          }}
                          className={`${
                            showBillingAddressForm ? "" : "hidden"
                          }`}
                        >
                          <Box>
                            <BillingAddressForm
                              autoComplete="on"
                              className="p-2"
                              reset={!showBillingAddressForm}
                            >
                              {/* <Grid columns={[1, 1]}>
                                <Box>
                                  <Box className="mt-1">
                                    <AddressInput
                                      data-cy="billing_address_company"
                                      name="billing_address_company"
                                      type="text"
                                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                      placeholder="Azienda"
                                      value={
                                        billingAddress.company &&
                                        billingAddress.company
                                      }
                                    />
                                  </Box>
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="email-error"
                                  >
                                    <Errors
                                      data-cy="billing_address_company_error"
                                      resource="billing_address"
                                      field="billing_address_company"
                                      messages={messages}
                                    />
                                  </p>
                                </Box>
                              </Grid> */}
                              <Grid columns={[1, 2]}>
                                <Box>
                                  <Box className="mt-1">
                                    <AddressInput
                                      data-cy="billing_address_first_name"
                                      name="billing_address_first_name"
                                      type="text"
                                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                      placeholder="Nome"
                                      value={
                                        billingAddress.first_name &&
                                        billingAddress.first_name
                                      }
                                    />
                                  </Box>
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="email-error"
                                  >
                                    <Errors
                                      data-cy="billing_address_first_name_error"
                                      resource="billing_address"
                                      field="billing_address_first_name"
                                      messages={messages}
                                    />
                                  </p>
                                </Box>
                                <Box>
                                  <Box className="mt-1">
                                    <AddressInput
                                      data-cy="billing_address_last_name"
                                      name="billing_address_last_name"
                                      type="text"
                                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                      placeholder="Cognome"
                                      value={
                                        billingAddress.last_name &&
                                        billingAddress.last_name
                                      }
                                    />
                                  </Box>
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="email-error"
                                  >
                                    <Errors
                                      data-cy="billing_address_last_name_error"
                                      resource="billing_address"
                                      field="billing_address_last_name"
                                      messages={messages}
                                    />
                                  </p>
                                </Box>
                              </Grid>
                              <Box sx={{ width: "100%" }}>
                                <Box className="mt-1">
                                  <AddressInput
                                    data-cy="billing_address_line_1"
                                    name="billing_address_line_1"
                                    type="text"
                                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Via"
                                    value={
                                      billingAddress.line_1 &&
                                      billingAddress.line_1
                                    }
                                  />
                                </Box>
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="email-error"
                                >
                                  <Errors
                                    data-cy="billing_address_line_1_error"
                                    resource="billing_address"
                                    field="billing_address_line_1"
                                    messages={messages}
                                  />
                                </p>
                              </Box>
                              <Box>
                                <Box className="mt-1">
                                  
                                  <AddressCountrySelector
                                    data-cy="billing_address_country_code"
                                    name="billing_address_country_code"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                                    placeholder={{
                                      value: "",
                                      label: "Paese",
                                      disabled: true,
                                    }}
                                    value={billingAddress.country_code}
                                  />
                                </Box>
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="email-error"
                                >
                                  <Errors
                                    data-cy="billing_address_country_code_error"
                                    resource="billing_address"
                                    field="billing_address_country_code"
                                    messages={messages}
                                  />
                                </p>
                              </Box>
                              <Grid columns={[1, 3]} gap={[3]}>
                                <Box>
                                  <Box className="mt-1">
                                    <AddressInput
                                      data-cy="billing_address_city"
                                      name="billing_address_city"
                                      type="text"
                                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                      placeholder="Città"
                                      value={
                                        billingAddress.city &&
                                        billingAddress.city
                                      }
                                    />
                                  </Box>
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="email-error"
                                  >
                                    <Errors
                                      data-cy="billing_address_city_error"
                                      resource="billing_address"
                                      field="billing_address_city"
                                      messages={messages}
                                    />
                                  </p>
                                </Box>

                                <Box>
                                  <Box className="mt-1">
                                    <AddressStateSelector
                                      data-cy="billing_address_state_code"
                                      name="billing_address_state_code"
                                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                                      inputClassName="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                                      placeholder={{
                                        value: "",
                                        label: "Provincia",
                                        disabled: true,
                                      }}
                                      value={
                                        billingAddress.state_code &&
                                        billingAddress.state_code
                                      }
                                    />
                                  </Box>
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="email-error"
                                  >
                                    <Errors
                                      data-cy="billing_address_state_code_error"
                                      resource="billing_address"
                                      field="billing_address_state_code"
                                      messages={messages}
                                    />
                                  </p>
                                </Box>
                                <Box>
                                  <Box className="mt-1">
                                    <AddressInput
                                      data-cy="billing_address_zip_code"
                                      name="billing_address_zip_code"
                                      type="text"
                                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                      placeholder="Zip code"
                                      value={
                                        billingAddress.zip_code &&
                                        billingAddress.zip_code
                                      }
                                    />
                                  </Box>
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="email-error"
                                  >
                                    <Errors
                                      data-cy="billing_address_zip_code_error"
                                      resource="billing_address"
                                      field="billing_address_zip_code"
                                      messages={messages}
                                    />
                                  </p>
                                </Box>
                              </Grid>
                              <Box>
                                <Box className="mt-1">
                                  {console.log(billingAddress)}
                                  <AddressInput
                                    data-cy="billing_address_phone"
                                    name="billing_address_phone"
                                    type="tel"
                                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Phone"
                                    value={
                                      billingAddress.phone &&
                                      billingAddress.phone
                                    }
                                  />
                                </Box>
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="email-error"
                                >
                                  <Errors
                                    data-cy="billing_address_phone_error"
                                    resource="billing_address"
                                    field="billing_address_phone"
                                    messages={messages}
                                  />
                                </p>
                              </Box>

                              <Box className="flex flex-row-reverse justify-end">
                                <Grid columns={["34px 1fr"]} gap={[1]}>
                                  <Box className="mt-1">
                                    <AddressInput
                                      data-cy="billing_address_save_to_customer_book"
                                      name="billing_address_save_to_customer_book"
                                      id="billing_address_save_to_customer_book"
                                      type="checkbox"
                                      className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                                      required={false}
                                    />
                                  </Box>
                                  <label
                                    htmlFor="billing_address_save_to_customer_book"
                                    className="block text-sm font-medium text-gray-700 ml-3 self-end"
                                  >
                                    Salva indirizzo di fatturazione
                                  </label>
                                </Grid>
                              </Box>
                            </BillingAddressForm>
                          </Box>
                        </Card>
                      </Box>
                      <Box sx={{ mt: 4 }}>
                        <Label sx={{ alignItems: "center" }}>
                          <Checkbox
                            defaultChecked={shipToDifferentAddress}
                            onChange={() =>
                              setShipToDifferentAddress(!shipToDifferentAddress)
                            }
                            data-cy="add-new-billing-address"
                          />
                          Spedisci a un indirizzo diverso
                        </Label>
                      </Box>
                      <Box
                        className={`${!shipToDifferentAddress ? "hidden" : ""}`}
                      >
                        <Box>
                          <Heading as="h5" variant="h5">
                            Indirizzo di spedizione
                          </Heading>
                        </Box>
                        <Box>
                          <Label>
                            <Radio
                              name="shipping-mode"
                              value={showShippingAddressForm ? "false" : "true"}
                              defaultChecked={!showShippingAddressForm}
                              onChange={e => {
                                setShowShippingAddressForm(false)
                              }}
                              disabled={!customerAddresses || customerAddresses.length === 0}
                            />
                            Seleziona un indirizzo dai tuoi indirizzi di
                            spedizione
                          </Label>
                        </Box>
                        <Box
                          sx={{
                            display: !showShippingAddressForm
                              ? "block"
                              : "none",
                          }}
                        >
                          <ShippingAddressContainer>
                            <Grid
                              columns={[1, 1, 1]}
                              gap={[3]}
                              sx={{
                                ".shipping-address-card-ctn": {
                                  ".shipping-address-card": {
                                    borderRadius: [4],
                                    border: "2px solid",
                                    borderColor: "transparent",
                                    position: "relative",
                                  },
                                  "&.selected-address-card": {
                                    ".shipping-address-card": {
                                      borderColor: "primary",
                                    },
                                    ".checked-icon": {
                                      boxSizing: "border-box",
                                      position: "absolute",
                                      top: "50%",
                                      display: "block",
                                      transform: "translateY(-50%)",
                                      width: "22px",
                                      height: "22px",
                                      border: "2px solid",
                                      borderRadius: "100px",
                                      borderColor: "primary",
                                      "&::after": {
                                        content: "''",
                                        display: "block",
                                        boxSizing: "border-box",
                                        position: "absolute",
                                        left: "3px",
                                        top: "-1px",
                                        width: "6px",
                                        height: "10px",
                                        color: "primary",
                                        borderColor: "primary",
                                        borderWidth: "0 2px 2px 0",
                                        borderStyle: "solid",
                                        transformOrigin: "bottom left",
                                        transform: "rotate(45deg)",
                                      },
                                    },
                                  },
                                },
                              }}
                            >
                              <Address
                                className="shipping-address-card-ctn"
                                selectedClassName="selected-address-card"
                                data-cy="customer-shipping-address"
                                deselect={showShippingAddressForm}
                                onSelect={() =>
                                  showShippingAddressForm &&
                                  setShowShippingAddressForm(false)
                                }
                              >
                                <Card
                                  className="shipping-address-card"
                                  sx={{ backgroundColor: "light" }}
                                >
                                  <Flex sx={{ ml: [4], p: { px: [3] } }}>
                                    <Box
                                      sx={{
                                        boxSizing: "border-box",
                                        position: "absolute",
                                        top: "50%",
                                        left: [3],
                                        display: "block",
                                        transform: "translateY(-50%)",
                                        width: "22px",
                                        height: "22px",
                                        border: "2px solid",
                                        borderRadius: "100px",
                                        borderColor: "gray",
                                      }}
                                      className="checked-icon"
                                    ></Box>
                                    <AddressField name="full_name"></AddressField>
                                    <AddressField name="full_address"></AddressField>
                                  </Flex>
                                </Card>
                              </Address>
                            </Grid>
                          </ShippingAddressContainer>
                        </Box>
                        <Box sx={{ py: [2] }}>
                          <Card
                            sx={{
                              pt: [2],
                              pb: [2],
                              px: [3],
                              borderRadius: [4],
                              border: "2px solid",
                              position: "relative",
                              borderColor: showShippingAddressForm
                                ? "primary"
                                : "transparent",
                              backgroundColor: "light",
                            }}
                          >
                            <Box sx={{ py: [3] }}>
                              <Label>
                                <Radio
                                  name="shipping-mode"
                                  value={showShippingAddressForm ? "true" : "false"}
                                  defaultChecked={showShippingAddressForm}
                                  onChange={e => {
                                    setShowShippingAddressForm(true)
                                  }}
                                />
                                Aggiungi un nuovo indirizzo di spedizione
                              </Label>
                            </Box>
                            <Box
                              className={`${
                                !showShippingAddressForm ? "hidden" : ""
                              }`}
                            >
                              <ShippingAddressForm
                                autoComplete="on"
                                className={
                                  shipToDifferentAddress
                                    ? `block p-2`
                                    : `hidden`
                                }
                                reset={!showShippingAddressForm}
                              >
                                {/* <Grid columns={[1, 1]}>
                                  <Box>
                                    <Box className="mt-1">
                                      <AddressInput
                                        data-cy="shipping_address_company"
                                        name="shipping_address_company"
                                        type="text"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Azienda"
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_company_error"
                                        resource="shipping_address"
                                        field="shipping_address_company"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>
                                </Grid> */}
                                <Grid columns={[1, 2]}>
                                  <Box>
                                    <Box className="mt-1">
                                      <AddressInput
                                        data-cy="shipping_address_first_name"
                                        name="shipping_address_first_name"
                                        type="text"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Nome"
                                        value={billingAddress.first_name}
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_first_name_error"
                                        resource="shipping_address"
                                        field="shipping_address_first_name"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>
                                  <Box>
                                    <Box className="mt-1">
                                      <AddressInput
                                        data-cy="shipping_address_last_name"
                                        name="shipping_address_last_name"
                                        type="text"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Cognome"
                                        value={billingAddress.last_name}
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_last_name_error"
                                        resource="shipping_address"
                                        field="shipping_address_last_name"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>
                                </Grid>
                                <Grid columns={[1, 1]}>
                                  <Box>
                                    <Box className="mt-1">
                                      <AddressInput
                                        data-cy="shipping_address_line_1"
                                        name="shipping_address_line_1"
                                        type="text"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Address"
                                        value={billingAddress.line_1}
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_line_1_error"
                                        resource="shipping_address"
                                        field="shipping_address_line_1"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>
                                </Grid>
                                <Grid>
                                  <Box>
                                    <Box className="mt-1">
                                      <AddressCountrySelector
                                        data-cy="shipping_address_country_code"
                                        name="shipping_address_country_code"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                                        placeholder={{
                                          value: "",
                                          label: "Paese",
                                          disabled: true,
                                        }}
                                        value={shippingAddress.country_code}
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_country_code_error"
                                        resource="shipping_address"
                                        field="shipping_address_country_code"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>
                                </Grid>
                                <Grid columns={[1, 3]}>
                                  <Box>
                                    <Box className="mt-1">
                                      <AddressInput
                                        data-cy="shipping_address_city"
                                        name="shipping_address_city"
                                        type="text"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="City"
                                        value={shippingAddress.city}
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_city_error"
                                        resource="shipping_address"
                                        field="shipping_address_city"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>

                                  <Box>
                                    <Box className="mt-1">
                                      <AddressStateSelector
                                        data-cy="shipping_address_state_code"
                                        name="shipping_address_state_code"
                                        type="text"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder={{
                                          value: "",
                                          label: "Provincia",
                                          disabled: true,
                                        }}
                                        value={
                                          shippingAddress.state_code &&
                                          shippingAddress.state_code
                                        }
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_state_code_error"
                                        resource="shipping_address"
                                        field="shipping_address_state_code"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>
                                  <Box>
                                    <Box className="mt-1">
                                      <AddressInput
                                        data-cy="shipping_address_zip_code"
                                        name="shipping_address_zip_code"
                                        type="text"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Zip code"
                                        value={shippingAddress.zip_code}
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_zip_code_error"
                                        resource="shipping_address"
                                        field="shipping_address_zip_code"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>
                                </Grid>
                                <Grid>
                                  <Box>
                                    <Box className="mt-1">
                                      <AddressInput
                                        data-cy="shipping_address_phone"
                                        name="shipping_address_phone"
                                        type="tel"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Phone"
                                        value={shippingAddress.phone}
                                      />
                                    </Box>
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="email-error"
                                    >
                                      <Errors
                                        data-cy="shipping_address_phone_error"
                                        resource="shipping_address"
                                        field="shipping_address_phone"
                                        messages={messages}
                                      />
                                    </p>
                                  </Box>
                                </Grid>
                                <Grid columns={["42px 1fr"]}>
                                  <Box className="mt-1">
                                    <AddressInput
                                      data-cy="shipping_address_save_to_customer_book"
                                      name="shipping_address_save_to_customer_book"
                                      id="shipping_address_save_to_customer_book"
                                      type="checkbox"
                                      className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                                      required={false}
                                    />
                                  </Box>
                                  <label
                                    htmlFor="shipping_address_save_to_customer_book"
                                    className="block text-sm font-medium text-gray-700 ml-3 self-end"
                                  >
                                    Salva indirizzo di spedizione
                                  </label>
                                </Grid>
                              </ShippingAddressForm>
                            </Box>
                          </Card>
                        </Box>
                      </Box>
                      <Box sx={{ my: [4] }}>
                        <SaveAddressesButton
                          label="Continua"
                          onClick={e => {
                            console.log(e)
                            handleClick()
                          }}
                        >
                          {props => {
                            return (
                              <Button
                                sx={{
                                  backgroundColor: !props.disabled
                                    ? "primary"
                                    : "gray",
                                  color: !props.disabled ? "light" : "text",
                                  cursor: !props.disabled
                                    ? "pointer"
                                    : "not-allowed",
                                  opacity: props.disabled && 0.5,
                                  textDecoration: props.disabled && "none",
                                }}
                                disabled={props.disabled}
                                variant="inputs.primary"
                                onClick={() =>
                                  props.handleClick().then(res => {
                                    console.log("red", res)
                                  })
                                }
                              >
                                Continua
                              </Button>
                            )
                          }}
                        </SaveAddressesButton>
                      </Box>
                    </AddressesContainer>
                    <Box className={`${checkoutStep > 0 ? "" : "hidden"}`}>
                      <Box>
                        <Heading as="h5" variant="h5">
                          Spedizione
                        </Heading>
                      </Box>
                      <ShipmentsContainer>
                        <Shipment loader={<>Caricamento...</>}>
                          <Card
                            sx={{
                              pt: [2],
                              pb: [2],
                              px: [3],
                              borderRadius: [4],
                              border: "2px solid",
                              position: "relative",
                              borderColor: showBillingAddressForm
                                ? "primary"
                                : "transparent",
                              backgroundColor: "light",
                            }}
                          >
                            <ShippingMethod>
                              <Grid columns={["42px 1fr"]} sx={{ py: [3] }}>
                                <ShippingMethodRadioButton
                                  data-cy="shipping-method-button"
                                  onChange={handleChange}
                                />
                                <ShippingMethodName data-cy="shipping-method-name">
                                  {props => {
                                    console.log(props)
                                    setCheckoutStep(2)
                                    console.log(checkoutStep)
                                    return (
                                      <span htmlFor={props.htmlFor}>
                                        Spedizione standard
                                      </span>
                                    )
                                  }}
                                </ShippingMethodName>
                                {/* <ShippingMethodPrice data-cy="shipping-method-price" /> */}
                                {/* <Box className="flex">
                                <DeliveryLeadTime
                                  type="min_days"
                                  data-cy="delivery-lead-time-min-days"
                                />
                                <DeliveryLeadTime
                                  type="max_days"
                                  data-cy="delivery-lead-time-max-days"
                                  className="mr-1"
                                />
                                days
                              </Box> */}
                              </Grid>
                            </ShippingMethod>
                          </Card>
                        </Shipment>
                      </ShipmentsContainer>
                    </Box>
                    <Box className={`${checkoutStep > 1 ? "" : "hidden"}`}>
                      <Box>
                        <Heading as="h5" variant="h5">
                          Pagamento
                        </Heading>
                      </Box>
                      <PaymentMethodsContainer>
                        <PlaceOrderContainer>
                          <PaymentMethod
                            className="p-2 my-1 flex items-center justify-items-center bg-gray-300 cursor-pointer"
                            activeClass="bg-opacity-25"
                            onClick={() => {
                              console.log("-custom click payment method-")
                            }}
                          >
                            <Card
                              sx={{
                                pt: [2],
                                pb: [2],
                                px: [3],
                                borderRadius: [4],
                                border: "2px solid",
                                position: "relative",
                                borderColor: showBillingAddressForm
                                  ? "primary"
                                  : "transparent",
                                backgroundColor: "light",
                              }}
                            >
                              <Grid columns={["42px 1fr"]} sx={{ py: [3] }}>
                                <PaymentMethodRadioButton />
                                <PaymentMethodName className="pl-3" />
                              </Grid>
                              <PaymentSource
                                data-cy="payment-source"
                                className="p-5 my-2"
                                loader={"Caricamento..."}
                              >
                                <Box className="flex flex-row items-center justify-start bg-gray-100 p-5 my-10">
                                  <Box className="flex flex-row items-center">
                                    <PaymentSourceBrandIcon className="mr-3" />
                                    <PaymentSourceBrandName
                                      className="mr-1"
                                      data-cy="payment-brand-name-card"
                                    />
                                    ending in
                                    <PaymentSourceDetail
                                      data-cy="payment-last4"
                                      className="ml-1"
                                      type="last4"
                                    />
                                  </Box>
                                  <Box className="text-gray-500 ml-5">
                                    <PaymentSourceDetail
                                      data-cy="payment-exp-month"
                                      type="expMonth"
                                    />
                                    <PaymentSourceDetail
                                      data-cy="payment-exp-year"
                                      type="expYear"
                                    />
                                  </Box>
                                  <Box className="ml-5">
                                    <PaymentSourceEditButton
                                      data-cy="payment-edit-button"
                                      className="text-blue-500 hover:underline hover:text-blue-600"
                                    />
                                  </Box>
                                </Box>
                              </PaymentSource>
                              <Errors
                                className="text-red-600 block"
                                resource="payment_methods"
                              />
                            </Card>
                          </PaymentMethod>
                          <Box sx={{ py: [3] }}>
                            <PlaceOrderButton
                              onClick={res => {
                                setOrderPlaced(res.placed)
                              }}
                              className="mt-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                            >
                              {props => {
                                return (
                                  <Button
                                    sx={{
                                      backgroundColor: !props.disabled
                                        ? "primary"
                                        : "gray",
                                      color: !props.disabled ? "light" : "text",
                                      cursor: !props.disabled
                                        ? "pointer"
                                        : "not-allowed",
                                      opacity: props.disabled && 0.5,
                                      textDecoration: props.disabled && "none",
                                    }}
                                    disabled={props.disabled}
                                    variant="inputs.primary"
                                    onClick={() => props.handleClick()}
                                  >
                                    Effettuare l'ordine
                                  </Button>
                                )
                              }}
                            </PlaceOrderButton>
                          </Box>
                        </PlaceOrderContainer>
                      </PaymentMethodsContainer>
                    </Box>

                    <Box className="flex flex-col text-red-600 mt-5">
                      <Errors resource="orders" messages={messages} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "lightGrey",
                      p: [4],
                      borderRadius: [5],
                    }}
                  >
                    <Heading sx={{ mb: 2 }}>Riassunto dell'ordine</Heading>
                    <Box sx={{ py: [2] }}>
                      <LineItemsContainer>
                        <Box>
                          <LineItem type="skus">
                            <Box
                              sx={{
                                py: [2],
                              }}
                            >
                              <LineItemCode>
                                {props => {
                                  const item = allSkus
                                    .map(sku => {
                                      if (props.lineItem.sku_code === sku.sku)
                                        return { ...sku, ...props.lineItem }
                                    })
                                    .filter(
                                      notUndefined => notUndefined !== undefined
                                    )[0]

                                  return (
                                    item && (
                                      <Flex
                                        columns={[4]}
                                        sx={{
                                          alignContent: "centet",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Flex>
                                          <Box
                                            sx={{
                                              width: ["60px"],
                                              height: ["60px"],
                                              top: 0,
                                              left: 0,
                                              ".gatsby-image-wrapper": {
                                                height: "100%",
                                              },
                                            }}
                                          >
                                            {item.image && (
                                              <GatsbyImage
                                                alt=""
                                                image={
                                                  item.image.gatsbyImageData
                                                }
                                                alt=""
                                              />
                                            )}
                                          </Box>
                                          <Flex
                                            sx={{
                                              flexDirection: "column",
                                              ml: [3],
                                            }}
                                          >
                                            <Box>
                                              <Heading
                                                as="h5"
                                                variant="h5"
                                                sx={{
                                                  my: 0,
                                                  mb: 2,
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                {item.displayName}
                                              </Heading>
                                            </Box>
                                            <Flex>
                                              <Text> {item.size.name} </Text>
                                              <Text> / </Text>
                                              <Text> {item.color.name} </Text>
                                            </Flex>
                                          </Flex>
                                        </Flex>

                                        <Flex>
                                          <Box sx={{ pr: [2] }}>
                                            {item.formatted_unit_amount}
                                          </Box>
                                          <Box sx={{ pr: [2] }}>x</Box>
                                          <Box>
                                            <LineItemQuantity
                                              readonly
                                              id="line-item-quantity"
                                              max={100}
                                            />
                                            <Errors
                                              resource="line_items"
                                              field="quantity"
                                            />
                                          </Box>
                                        </Flex>
                                      </Flex>
                                    )
                                  )
                                }}
                              </LineItemCode>
                            </Box>
                          </LineItem>
                        </Box>
                      </LineItemsContainer>
                    </Box>
                    <Divider />
                    <Flex sx={{ justifyContent: "space-between" }}>
                      <Text>Subtotale</Text>
                      <Text>
                        <SubTotalAmount />
                      </Text>
                    </Flex>
                    <Flex sx={{ justifyContent: "space-between" }}>
                      <Text>Spedizione</Text>
                      <Text>
                        <ShippingAmount />
                      </Text>
                    </Flex>
                    <Flex sx={{ justifyContent: "space-between" }}>
                      <Text>Tasse</Text>
                      <Text>
                        <TaxesAmount />
                      </Text>
                    </Flex>
                    <Divider />
                    <Flex sx={{ justifyContent: "space-between" }}>
                      <Text>Totale</Text>
                      <Text>
                        <TotalAmount id="total-amount" />
                      </Text>
                    </Flex>
                    <Flex
                      sx={{ pt: [4], justifyContent: "center", width: "100%" }}
                    ></Flex>
                  </Box>
                </Flex>
              </CustomerContainer>
            </OrderContainer>
            <Box className="mt-5">
              {/* <pre data-cy="current-shipping-method">{`Current payment source options: ${JSON.stringify(
            paymentSource,
            null,
            2
          )}`}</pre> */}
            </Box>
          </Box>
        </CommerceLayer>
      )
      )}
    </Container>
  )
}

export default CheckoutPage
