import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const useProducts = () => {
  const products = useStaticQuery(graphql`
    query ProductsQuery {
      allDatoCmsProduct(sort: {fields: name, order: ASC}) {
        nodes {
          id
          name
          description
          gender
          retailPrice
          category {
            id
            name
          }
          variants {
            id
            displayName
            sku
            image {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                forceBlurhash: false
              )
            }
            size {
              id
              name
            }
            color {
              id
              name
              colorSwitcher {
                rgb
                hex
              }
            }
          }
        }
      }
    }
  `)

  return products.allDatoCmsProduct.nodes
}
