import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { useAuth } from "../hooks/useAuth"
import { checkTokenExpiration, logout, getUser } from "../utils/auth"
const isBrowser = typeof window !== `undefined`

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { token, setToken } = useAuth()

  const now = Date.now() / 1000
  const tokenExpiration = Date.parse(getUser().tokenExpiration) / 1000
  
  const tokenExpired =  now > tokenExpiration


  if (isBrowser && token && tokenExpired) {
    // const response = await refreshToken();
    // setToken(response.accessToken)

    logout(() => {
      setToken(null)
      navigate(`/`)
    })
  } else if (isBrowser && !token && location.pathname !== `/`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/`)
    return null
  }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
