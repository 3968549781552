import React, { useState, useEffect } from "react"
import { useAuth } from "../../hooks/useAuth"
import { getUser } from "../../utils/auth"
import { useSDK } from "../../hooks/useSDK"
import { Heading, Container, Box } from "@theme-ui/components"
import { Link } from "gatsby"
import ReactPaginate from "react-paginate"
import { i18nContext } from "../../utils/i18nContext"

const Orders = () => {
  const { ownerId } = getUser()
  const { token, setToken } = useAuth()
  const endpoint = "https://dkb.commercelayer.io"
  const config = { accessToken: token, endpoint }
  const [orders, setOrders] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [sdk, setSDK] = useState(useSDK())
  const [filteredVariants, setFilteredVariants] = useState(orders)
  const [itemOffset, setItemOffset] = useState(0)
  const [itemsPerPage, setItemPerPage] = useState(10)
  const [currentItems, setCurrentItems] = useState(filteredVariants)
  const [pageCount, setPageCount] = useState(0)
  const [forcePage, setForcePage] = useState(0)

  useEffect(() => {
    async function getOrders() {
      const getCustomer = await sdk.customers.retrieve(ownerId, {
        include: ["orders"],
      })

      if (getCustomer.orders) {
        const filteredOrders = getCustomer.orders.filter(order => {
          return order.status === "approved" || order.status === "placed"
        })
        setCustomer(getCustomer)
        setOrders(filteredOrders)
        setFilteredVariants(filteredOrders)
      }
    }
    getOrders()
  }, [])

  useEffect(() => {
    if (filteredVariants) {
      const endOffset = itemOffset + itemsPerPage
      setCurrentItems(filteredVariants.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(filteredVariants.length / itemsPerPage))
    }
  }, [orders, itemOffset, itemsPerPage, filteredVariants])

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % filteredVariants.length
    setItemOffset(newOffset)
  }

  return (
    <Box>
      <Container>
        <Heading>Ordini</Heading>
      </Container>
      {currentItems && currentItems.length > 0 ? (
        <Container
          sx={{
            marginTop: 0,
            marginBottom: 6,
            padding: 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#ffffff",
              boxShadow:
                "rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px",
              borderRadius: "sm",
            }}
          >
            <Container>
              <Box>
                <Box
                  as="table"
                  sx={{
                    width: "100%",
                    td: {
                      paddingX: 3,
                      paddingY: 1,
                      fontSize: 1,
                    },
                    th: {
                      textAlign: "left",
                      paddingX: 3,
                      paddingY: 3,
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontSize: 0,
                    },
                  }}
                >
                  <Box as="thead" sx={{ borderBottom: "1px solid #ddd" }}>
                    <tr>
                      <th>Numero</th>
                      <th>Totale</th>
                      <th>Stato</th>
                      <th>Pagamento</th>
                      <th>Spedizione</th>
                      <th>Data</th>
                      <th></th>
                    </tr>
                  </Box>
                  <Box as="tbody">
                    {currentItems &&
                      currentItems.reverse().map(order => (
                        <Box as="tr" key={order.number}>
                          <i18nContext.Consumer>
                            {t => (
                              <>
                                <td>{order.number}</td>
                                <td>{order.formatted_total_amount}</td>
                                <td>{t[order.status]}</td>
                                <td>{t[order.payment_status]}</td>
                                <td>{t[order.fulfillment_status]}</td>
                                <td>
                                  {new Date(order.placed_at)
                                    .toLocaleString("it")
                                    .toString()}
                                </td>
                                <td>
                                  {
                                    <Box
                                      sx={{
                                        m: [0],
                                        appearance: "none",
                                        display: "inline-block",
                                        textAlign: "center",
                                        px: [3],
                                        py: [2],
                                        color: "light",
                                        border: 0,
                                        borderRadius: "4px",
                                        backgroundColor: "primary",
                                        a: {
                                          textDecoration: "none",
                                        },
                                      }}
                                    >
                                      <Link
                                        to={`/dashboard/ordini/${order.id}`}
                                      >
                                        Dettagli
                                      </Link>
                                    </Box>
                                  }
                                </td>
                              </>
                            )}
                          </i18nContext.Consumer>
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Container>
            <Container
              sx={{
                borderTop: "1px solid #eee",
                ".pagination": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  listStyle: "none",
                  fontSize: 1,
                  li: {
                    cursor: "pointer",
                    mx: 3,
                    "&.selected": {
                      a: {
                        color: "primary",
                        fontWeight: "bold",
                      },
                    },
                    "&:hover": {
                      color: "primary",
                    },
                  },
                },
              }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel="successivo ›"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="‹ precedente"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                forcePage={forcePage}
              />
            </Container>
          </Box>
        </Container>
      ) : (
        <Container
          sx={{
            marginTop: 0,
            marginBottom: 6,
            padding: 0,
          }}
        >
          <Box
            sx={{
              p: [4],
              backgroundColor: "#ffffff",
              boxShadow:
                "rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px",
              borderRadius: "sm",
            }}
          >
            Non è stato effettuato nessun ordine
          </Box>
        </Container>
      )}
    </Box>
  )
}

export default Orders
