import React, { useState, useEffect, useMemo } from "react"
import { getSalesChannelToken, getCustomerToken } from "@commercelayer/js-auth"
import { useAuth } from "../../../hooks/useAuth"
import { getUser } from "../../../utils/auth"
import { useSDK } from "../../../hooks/useSDK"
import { useVariant } from "../../../hooks/useSKU"
import CommerceLayer from "@commercelayer/sdk"
import {
  Card,
  Container,
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Grid,
  Divider,
} from "@theme-ui/components"
import { Table } from "../../ordersTable"
import { GatsbyImage } from "gatsby-plugin-image"
import clothesPlaceholder from "../../../images/clothes.jpeg"

const Order = page => {
  const orderId = page.uri.split("/").pop()
  const { token, setToken } = useAuth()
  const endpoint = "https://dkb.commercelayer.io"
  const config = { accessToken: token, endpoint }
  const [order, setOrder] = useState(null)
  const [sdk, setSDK] = useState(useSDK())
  const [orderSKUs, setOrderSKUs] = useState(null)
  const allSkus = useVariant()

  const handleOrder = async () => {
    let lineItems = order.line_items // sku_code

    const skus = [
      ...new Set([
        ...lineItems.filter(e => {
          if (e) return e.sku_cod
        }),
        ...allSkus.map(e => {
          if (e) return e.sku
        }),
      ]),
    ]
    const result = skus.map(e => ({
      ...allSkus.find(o => o.sku === e),
      ...lineItems.find(o => o.sku_code === e),
    }))

    setOrderSKUs(
      result.filter(item => {
        return item.sku_code
      })
    )
  }

  const tableData = allSkus.map(variant => {
    return {
      displayName: variant.displayName,
      colorName: variant.color.name,
      image: variant.image,
      size: variant.size.name,
      sku: variant.sku,
    }
  })

  useEffect(() => {
    async function getOrder() {
      const orderResponse = await sdk.orders.retrieve(orderId, {
        include: [
          "shipping_address",
          "billing_address",
          "payment_source",
          "line_items",
        ],
      })

      if (orderResponse) {
        setOrder(orderResponse)
      }
    }

    if (!order) getOrder()
    if (order) handleOrder()
  }, [order])

  const renderPaymentTextSwitch = param => {
    switch (param) {
      case "authorized":
        return "Pagamento in attesa"
      case "paid":
        return "Pagato"
      case "voided":
        return "Pagamento anullato"
      case "refunded":
        return "Pagamento rimborsato"
    }
  }

  const renderPaymentColor = param => {
    switch (param) {
      case "authorized":
        return {
          backgroundColor: "#ffe169",
          color: "#ff6000",
        }
      case "paid":
        return {
          backgroundColor: "#def7ec",
          color: "#03543F",
        }
      case "voided":
        return {
          backgroundColor: "#ef233c",
          color: "#ff8fa3",
        }
      case "refunded":
        return {
          backgroundColor: "#caf0f8",
          color: "#0077b6",
        }
    }
  }

  return (
    order && (
      <Container>
        <Flex
          sx={{
            alignItems: "center",
          }}
        >
          <Box>
            <Heading>Ordine #{order.number}</Heading>{" "}
          </Box>
          {/* <Box
            sx={{
              ml: [2],
              py: [2],
              px: [2],
              borderRadius: [4],
              backgroundColor: renderPaymentColor(order.payment_status)
                .backgroundColor,
              color: renderPaymentColor(order.payment_status).color,
            }}
          >
            <Text color="">
              {renderPaymentTextSwitch(order.payment_status)}
            </Text>
          </Box> */}
        </Flex>
        <Box sx={{ pb: [3] }}>
          <Text sx={{ color: "text" }}>
            Creato il:{" "}
            {new Date(order.placed_at).toLocaleString("it").toString()}
          </Text>
        </Box>
        <Box sx={{ backgroundColor: "lightGrey", p: [4], borderRadius: [5] }}>
          <Heading as="h4" variant="h3" sx={{ my: 4, mt: 0 }}>
            Prodotti
          </Heading>
          {orderSKUs &&
            orderSKUs.map((item, i) => (
              <Grid key={item.sku_code + i} columns={[5]}>
                <Box
                  sx={{
                    width: ["120px"],
                    height: ["120px"],
                    top: 0,
                    left: 0,
                    ".gatsby-image-wrapper": {
                      height: "100%",
                    },
                  }}
                >
                  {item.image ? (
                    <GatsbyImage alt="" image={item.image.gatsbyImageData} />
                  ) : (
                    <Image sx={{}} src={clothesPlaceholder} />
                  )}
                </Box>
                <Flex sx={{ flexDirection: "column" }}>
                  <Box>
                    <Heading
                      as="h5"
                      variant="h5"
                      sx={{ my: 0, mb: 2, fontWeight: "normal" }}
                    >
                      {item.displayName}
                    </Heading>
                  </Box>
                  <Box>
                    <Text sx={{ span: { fontWeight: "bold" } }}>
                      Taglia: <span>{item.size.name}</span>
                    </Text>
                  </Box>
                  <Box>
                    <Text sx={{ span: { fontWeight: "bold" } }}>
                      Colore: <span>{item.color.name}</span>
                    </Text>
                  </Box>
                </Flex>
                <Box>{item.formatted_unit_amount}</Box>
                <Box>x{item.quantity}</Box>
                <Box>{item.formatted_total_amount}</Box>
              </Grid>
            ))}
        </Box>
        <Grid columns={[1, 2]}>
          <Box
            sx={{
              my: [4],
              backgroundColor: "lightGrey",
              p: [4],
              borderRadius: [5],
            }}
          >
            <Heading sx={{ mb: 2 }}>Riassunto dell'ordine</Heading>
            <Flex sx={{ justifyContent: "space-between" }}>
              <Text>Subtotale</Text>
              <Text>{order.formatted_subtotal_amount}</Text>
            </Flex>
            <Flex sx={{ justifyContent: "space-between" }}>
              <Text>Spedizione</Text>
              <Text>{order.formatted_shipping_amount}</Text>
            </Flex>
            <Flex sx={{ justifyContent: "space-between" }}>
              <Text>Tasse</Text>
              <Text>{order.formatted_adjustment_tax_amount}</Text>
            </Flex>
            <Divider />
            <Flex sx={{ justifyContent: "space-between" }}>
              <Text>Totale</Text>
              <Text>{order.formatted_total_amount}</Text>
            </Flex>
          </Box>
          <Box
            sx={{
              my: [4],
              backgroundColor: "lightGrey",
              p: [4],
              borderRadius: [5],
            }}
          >
            <Heading sx={{ mb: 2 }}>Indirizzi</Heading>
            <Divider />
            <Grid columns={[2]}>
              <Flex sx={{ flexDirection: "column" }}>
                <Text sx={{ fontWeight: "500", pb: [2] }}>Fatturazione</Text>
                <Text>{order.billing_address.full_address}</Text>
              </Flex>
              <Flex sx={{ flexDirection: "column" }}>
                <Text sx={{ fontWeight: "500", pb: [2] }}>Spedizione</Text>
                <Text>{order.billing_address.full_address}</Text>
              </Flex>
            </Grid>
          </Box>
        </Grid>
      </Container>
    )
  )
}

export default Order
