import React, { useState, useEffect } from "react"

import { getSalesChannelToken, getCustomerToken } from "@commercelayer/js-auth"
import {
  CommerceLayer,
  OrderStorage,
  OrderContainer,
  LineItemsContainer,
  LineItemsCount,
  LineItem,
  LineItemImage,
  LineItemName,
  LineItemCode,
  Errors,
  LineItemAmount,
  LineItemQuantity,
  LineItemRemoveLink,
  TaxesAmount,
  SubTotalAmount,
  ShippingAmount,
  TotalAmount,
  CheckoutLink,
} from "@commercelayer/react-components"
import { useAuth } from "../hooks/useAuth"
import { Link } from "gatsby"
import {
  Box,
  Image,
  Flex,
  Heading,
  Text,
  Grid,
  Divider,
  Container,
} from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { useVariant } from "../hooks/useSKU"
import { X } from "react-feather"
import clothesPlaceholder from "../images/clothes.jpeg"

const CustomAddToCart = props => {
  const classes = props.disabled ? "opacity-50 cursor-not-allowed" : ""
  const myClick = () => {
    props.handleClick()
  }
  return (
    <button
      id="add-to-bag"
      className={`${classes} ${props.className}`}
      onClick={myClick}
      disabled={props.disabled}
    >
      Custom add to cart
    </button>
  )
}

const Cart = () => {
  const { token, setToken, cartCounter, setCartCounter } = useAuth()
  const allSkus = useVariant()
  const endpoint = "https://dkb.commercelayer.io"

  // useEffect(() => {
  //   if(props.quantity)
  //   setCartCounter(props.quantity)
  // }, [props.quantity])

  return (
    <Box>
      <Container>
        <Heading>Carrello</Heading>
      </Container>
      <Container
        sx={{
          marginTop: 0,
          marginBottom: 6,
          padding: 0,
        }}
      >
        <CommerceLayer accessToken={token} endpoint={endpoint}>
          <OrderStorage persistKey="DKBITA">
            <OrderContainer attributes={{ language_code: "it" }}>
              <Grid columns={[1, cartCounter > 0 ? "4fr 1fr" : 1]}>
                <Box
                  sx={{
                    p: [4],
                    backgroundColor: "#ffffff",
                    boxShadow:
                      "rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px",
                    borderRadius: "sm",
                  }}
                >
                  <LineItemsContainer>
                    <LineItemsCount id="items-count" className="font-bold">
                      {props => {
                        setCartCounter(props.quantity)
                        return null
                      }}
                    </LineItemsCount>
                    {cartCounter > 0 ? (
                      <Box
                        as="table"
                        sx={{
                          width: "100%",
                          border: 0,
                          borderCollapse: "separate",
                          borderSpacing: "0 5px",
                          td: {
                            paddingX: 3,
                            paddingY: 1,
                            fontSize: 1,
                          },
                          th: {
                            textAlign: "left",
                            paddingX: 3,
                            paddingY: 3,
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            fontSize: 0,
                            borderBottom: "1px solid #eee",
                            borderCollapse: "separate",
                            borderSpacing: "5px 5px",
                          },
                        }}
                      >
                        <Box as="thead" sx={{ borderBottom: "1px solid #ddd" }}>
                          <tr>
                            <th></th>
                            <th>Nome</th>
                            <th>Taglia</th>
                            <th>Colore</th>
                            <th>Sku</th>
                            <th>Prezzo</th>
                            <th>Quantità</th>
                            <th>Totale</th>
                            <th></th>
                          </tr>
                        </Box>
                        <LineItem type="skus">
                          <LineItemCode>
                            {props => {
                              const item = allSkus
                                .map(sku => {
                                  if (props.lineItem.sku_code === sku.sku)
                                    return { ...sku, ...props.lineItem }
                                })
                                .filter(
                                  notUndefined => notUndefined !== undefined
                                )[0]

                              return (
                                item && (
                                  <tr>
                                    <td>
                                      <Box
                                        sx={{
                                          maxWidth: "48px",
                                          minWidth: "48px",
                                          borderRadius: "sm",
                                          overflow: "hidden",
                                          border: "1px solid #eeeeee",
                                        }}
                                      >
                                        {item.image ? (
                                          <GatsbyImage
                                            alt=""
                                            image={item.image.gatsbyImageData}
                                          />
                                        ) : (
                                          <Image
                                            sx={{}}
                                            src={clothesPlaceholder}
                                          />
                                        )}
                                      </Box>
                                    </td>
                                    <td>{item.displayName}</td>
                                    <td>{item.size.name}</td>
                                    <td>{item.color.name}</td>
                                    <td>{item.sku}</td>
                                    <td>{item.formatted_unit_amount}</td>
                                    <td>
                                      <LineItemQuantity
                                        id="line-item-quantity"
                                        max={100}
                                      />
                                      <Errors
                                        resource="line_items"
                                        field="quantity"
                                      />
                                    </td>
                                    <td>{item.formatted_total_amount}</td>
                                    <td>
                                      <Box>
                                        <LineItemRemoveLink id="line-item-remove">
                                          {props => (
                                            <Box
                                              sx={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                              }}
                                              onClick={e => {
                                                props.handleRemove(e)
                                              }}
                                            >
                                              Rimuovi
                                            </Box>
                                          )}
                                        </LineItemRemoveLink>
                                      </Box>
                                    </td>
                                  </tr>
                                )
                              )
                            }}
                          </LineItemCode>
                        </LineItem>
                      </Box>
                    ) : (
                      <Box>Il carrello non contiene prodotti</Box>
                    )}
                  </LineItemsContainer>
                </Box>
                {cartCounter > 0 && (
                  <Box
                    sx={{
                      p: [4],
                      backgroundColor: "#ffffff",
                      boxShadow:
                        "rgba(23, 24, 24, 0.05) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 2px 0px",
                      borderRadius: "sm",
                      fontSize: 1,
                    }}
                  >
                    <Heading sx={{ mb: 2, fontSize: 3 }}>Ordine</Heading>
                    <Flex sx={{ justifyContent: "space-between" }}>
                      <Text>Prodotti</Text>
                      <Text>{cartCounter}</Text>
                    </Flex>
                    <Flex sx={{ justifyContent: "space-between" }}>
                      <Text>Subtotale</Text>
                      <Text>
                        <SubTotalAmount />
                      </Text>
                    </Flex>
                    <Flex sx={{ justifyContent: "space-between" }}>
                      <Text>Spedizione</Text>
                      <Text>
                        <ShippingAmount />
                      </Text>
                    </Flex>
                    <Divider />
                    <Flex
                      sx={{
                        justifyContent: "space-between",
                        fontSize: 2,
                        fontWeight: "bold",
                        mb: 2,
                      }}
                    >
                      <Text>Totale</Text>
                      <Text>
                        <TotalAmount id="total-amount" />
                      </Text>
                    </Flex>
                    <CheckoutLink>
                      {props => (
                        <a
                          href={props.checkoutUrl + "?accessToken=" + token}
                          target="_blank"
                        >
                          <Box
                            sx={{
                              width: "100%",
                              textAlign: "center",
                              textDecoration: "none",
                              px: [4],
                              py: [3],
                              backgroundColor: "primary",
                              color: "light",
                              cursor: "pointer",
                            }}
                          >
                            Checkout
                          </Box>
                        </a>
                      )}
                    </CheckoutLink>
                  </Box>
                )}
              </Grid>
            </OrderContainer>
          </OrderStorage>
        </CommerceLayer>
      </Container>
    </Box>
  )
}

export default Cart
