import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const useVariant = () => {
  const variants = useStaticQuery(graphql`
    query VariantQuery {
      allDatoCmsVariant {
        nodes {
          id
          displayName
          sku
          image {
            gatsbyImageData(width: 300, placeholder: BLURRED)
          }
          size {
            id
            name
          }
          color {
            id
            name
            colorSwitcher {
              rgb
              hex
            }
          }
        }
      }
    }
  `)

  const i18nVariant = variants.allDatoCmsVariant.nodes.filter(link => link)

  return i18nVariant
}
