import React from "react"
import { Router } from "@reach/router"
import Profile from "../components/profile"
import PrivateRoute from "../components/privateRoute"
import Cart from "../components/cart"
import Dashboard from "../components/dashboard"
import Checkout from "../components/checkout"
import Orders from "../components/Orders"
import Order from "../components/Orders/Order"
import GlobalLayout from "../components/globalLayout"
import LoginForm from "../components/loginForm"

const Store = () => {
  
  return (
  <GlobalLayout>
    <Router>
      <PrivateRoute path="/dashboard/checkout/:orderId" component={Checkout} />
      <PrivateRoute path="/dashboard/profilo" component={Profile} />
      <PrivateRoute path="/dashboard/ordini" component={Orders} />
      <PrivateRoute path="/dashboard/ordini/:orderId" component={Order} />
      <PrivateRoute path="/dashboard/carrello" component={Cart} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <LoginForm path="/" /> 
    </Router>
  </GlobalLayout>)
}

export default Store
