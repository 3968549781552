import React, { useState } from "react"
import {
  PricesContainer,
  Price,
  QuantitySelector,
  AddToCartButton,
  ItemContainer,
  AvailabilityContainer,
  AvailabilityTemplate,
} from "@commercelayer/react-components"
import { Box, Image, Text, Flex, Button } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import clothesPlaceholder from "../images/clothes.jpeg"

const Product = ({ product, handleSubmit, size, color }) => {
  const [showVariants, setShowVariants] = useState(false)
  //   console.log(product)
  return (
    <Box key={product.id}>
      <Flex
        sx={{
          flexDirection: "row",
          alignItems: "center",
          py: 2,
          px: 2,
          cursor: "pointer",
          "&:hover": { backgroundColor: "#fafafa" },
        }}
        onClick={() => setShowVariants(!showVariants)}
      >
        <Box
          sx={{
            maxWidth: "64px",
            minWidth: "64px",
            borderRadius: "sm",
            overflow: "hidden",
            border: "1px solid #eeeeee",
            mr: [3],
          }}
        >
          {product.variants[0].image ? (
            <GatsbyImage
              alt=""
              image={product.variants[0].image.gatsbyImageData}
            />
          ) : (
            <Image sx={{}} src={clothesPlaceholder} />
          )}
        </Box>
        <Box>
          <Text
            as="p"
            sx={{
              textTransform: "uppercase",
              color: "primary",
              fontSize: 2,
              fontWeight: "bold",
            }}
          >
            {product.name}
          </Text>
          <Text
            as="p"
            sx={{
              fontSize: 0,
              color: "#999",
              backgroundColor: "#ededed",
              px: 2,
              borderRadius: "full",
              display: "inline-block",
              mr: 1,
            }}
          >
            {product.category.name}
          </Text>
          <Text
            as="p"
            sx={{
              fontSize: 0,
              color: "#999",
              backgroundColor: "#ededed",
              px: 2,
              borderRadius: "full",
              display: "inline-block",
            }}
          >
            {product.variants.length} varianti
          </Text>
        </Box>
      </Flex>
      {showVariants && (
        <Box
          as="table"
          sx={{
            width: "100%",
            border: 0,
            borderCollapse: "separate",
            borderSpacing: "0 5px",
            td: {
              paddingX: 3,
              paddingY: 1,
              fontSize: 1,
              borderBottom: "1px solid #eee",
              borderCollapse: "separate",
              borderSpacing: "5px 5px",
              "&::first-child": {
                paddingLeft: 0,
              },
            },
            th: {
              textAlign: "left",
              paddingX: 3,
              paddingY: 3,
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 0,
              borderBottom: "1px solid #eee",
              borderCollapse: "separate",
              borderSpacing: "5px 5px",
            },
          }}
        >
          <Box as="thead" sx={{ borderBottom: "1px solid #ddd" }}>
            <tr>
              <th></th>
              <th>
                <Text
                  sx={{
                    cursor: "pointer",
                    fontSize: 1,
                    color: "light",
                    backgroundColor: "dark",
                    py: 1,
                    px: 2,
                    borderRadius: "full",
                    "&:hover": {
                      backgroundColor: "primary",
                    },
                  }}
                  onClick={() => handleSubmit(product)}
                >
                  Dettagli
                </Text>
              </th>
              <th>Taglia</th>
              <th>Colore</th>
              <th>Sku</th>
              <th>Prezzo</th>
              <th>Disponibilità</th>
              <th>Quantità</th>
              <th></th>
            </tr>
          </Box>
          <Box as="tbody">
            {product.variants.map(variant => (
              <>
                {(size === "all" || variant.size.name === size) &&
                  (color === "all" || variant.color.name === color) && (
                    <Box as="tr" key={variant.sku}>
                      <ItemContainer>
                        <td>
                          <Box
                            sx={{
                              maxWidth: "48px",
                              minWidth: "48px",
                              borderRadius: "sm",
                              overflow: "hidden",
                              border: "1px solid #eeeeee",
                            }}
                          >
                            {variant.image ? (
                              <GatsbyImage
                                alt=""
                                image={variant.image.gatsbyImageData}
                              />
                            ) : (
                              <Image sx={{}} src={clothesPlaceholder} />
                            )}
                          </Box>
                        </td>
                        <td>{variant.displayName}</td>
                        <td>{variant.size.name}</td>
                        <td>{variant.color.name}</td>
                        <td>{variant.sku}</td>
                        <td>
                          <PricesContainer skuCode={variant.sku}>
                            <Price skuCode={variant.sku}>
                              {t => {
                                return (
                                  <Box>
                                    {!t.loading && t.prices.length > 0 && (
                                      <p>{t.prices[0].formatted_amount}</p>
                                    )}
                                    {!t.loading && t.prices.length === 0 && (
                                      <p>-</p>
                                    )}
                                  </Box>
                                )
                              }}
                            </Price>
                          </PricesContainer>
                        </td>
                        <td>
                          <AvailabilityContainer skuCode={variant.sku}>
                            <AvailabilityTemplate>
                              {props => {
                                return (
                                  <>
                                    {props.quantity > 0 ? (
                                      <Text
                                        sx={{
                                          color: "#49b675",
                                        }}
                                      >
                                        Disponibile
                                      </Text>
                                    ) : (
                                      <Text sx={{ color: "#ccc" }}>
                                        Non disponibile
                                      </Text>
                                    )}
                                  </>
                                )
                              }}
                            </AvailabilityTemplate>
                          </AvailabilityContainer>
                        </td>
                        <Box as="td" sx={{ input: { width: "100%" } }}>
                          <QuantitySelector skuCode={variant.sku} />
                        </Box>
                        <td>
                          <AddToCartButton>{CustomAddToCart}</AddToCartButton>
                        </td>
                      </ItemContainer>
                    </Box>
                  )}
              </>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const CustomAddToCart = props => {
  const [loading, setLoading] = useState(false)
  return (
    <Button
      onClick={() => {
        setLoading(true)
        props.handleClick().then(e => {
          setLoading(false)
        })
      }}
      variant={props.disabled ? "disabled" : "primary"}
      disabled={props.disabled}
      sx={{ width: "100%" }}
    >
      {loading ? `...` : `Aggiungi`}
    </Button>
  )
}

export default Product
