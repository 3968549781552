import React from "react"

export const languages = {
  it: {
    draft: "Bozza",
    pending: "In attesa",
    placed : "Creato",
    approved: "Approvato",
    cancelled: "Cancellato",
    authorized: "In attesa",
    paid: "Pagato",
    voided: "Annullato",
    refunded: "Rimborsato",
    unfulfilled: "Da spedire",
    in_progress: "In attesa",
    fulfilled: "Spedito",
  },
}

export const i18nContext = React.createContext(languages.it)
