import React from "react"
import { useAuth } from "./useAuth"
import CommerceLayer from "@commercelayer/sdk"

export const useSDK = () => {
    const { token, setToken } = useAuth()

    return CommerceLayer({
        organization: "dkb",
        accessToken: token,
    })
}
