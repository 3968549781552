import React from "react"
import { Helmet } from "react-helmet"
import GlobalLayout from "../components/globalLayout"
import LoginForm from "../components/loginForm"

const Index = () => (
  <GlobalLayout>
    <Helmet>
      <title>Login | DKB Sport</title>
    </Helmet>
    <LoginForm />
  </GlobalLayout>
)

export default Index
